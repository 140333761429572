import { default as indexPNEZVlTgLdMeta } from "/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/conversations/[sid]/index.vue?macro=true";
import { default as _91sid_935X23l5t7gPMeta } from "/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/conversations/[sid].vue?macro=true";
import { default as indexu9ZDwDjAdTMeta } from "/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/index.vue?macro=true";
import { default as indexTXZHdFbPogMeta } from "/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/login/agent/index.vue?macro=true";
import { default as agent2nmkU7LDuxMeta } from "/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/login/agent.vue?macro=true";
export default [
  {
    name: _91sid_935X23l5t7gPMeta?.name,
    path: "/conversations/:sid()",
    component: () => import("/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/conversations/[sid].vue").then(m => m.default || m),
    children: [
  {
    name: "conversations-sid",
    path: "",
    component: () => import("/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/conversations/[sid]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agent2nmkU7LDuxMeta?.name,
    path: "/login/agent",
    component: () => import("/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/login/agent.vue").then(m => m.default || m),
    children: [
  {
    name: "login-agent",
    path: "",
    component: () => import("/builds/nht4944308/products/malarkey/malarkey-nuxt-web-app/pages/login/agent/index.vue").then(m => m.default || m)
  }
]
  }
]